import { removeCacheItem } from '../custom';
import type { EbizioOrderEventConfig } from '../types';
import { EBIZIO_ORDER_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const orderConfirmationLoadedConfig: EbizioOrderEventConfig = {
  event: EBIZIO_ORDER_EVENT_TYPE.ORDER_CONFIRMATION_LOADED,
  handler: ({ detail }) => {
    logger('Order Confirmation Loaded');
    console.log(detail);

    if (detail.order) {
      removeCacheItem(`firstCartItem-${detail.order.cartId}`);
      removeCacheItem(`preOrderProduct-${detail.order.cartId}`);
      removeCacheItem(`cartPreview-${detail.order.cartId}`);
    }
  },
};
