declare global {
  interface Window {
    EbizioEventManager: {
      pageType: string;
      checkoutId: string;
      gqlToken: string;
      cartCouponCode: string;
    };
    refreshCartData: () => Promise<void>;
  }
}

import type { EbizioEventConfig, EbizioOrderEventConfig } from './types';
import { CHECKOUT_CONTAINER, COUPON_STORAGE_KEY } from './constants';
import {
  billingStepLoadingConfig,
  cartSummaryDesktopMountedConfig,
  cartSummaryMobileMountedConfig,
  cartSummaryModalOpenedConfig,
  checkoutLoadedConfig,
  couponsChangeddConfig,
  customerSignedInConfig,
  customerSignedOutConfig,
  customerStepLoadingConfig,
  giftCertificatesChangedConfig,
  orderConfirmationLoadedConfig,
  paymentMethodLoadedConfig,
  paymentMethodSelectedConfig,
  paymentStepLoadingConfig,
  paymentStepSubmittedConfig,
  savedShippingAddressChangedConfig,
  shippingMethodSelectedConfig,
  shippingStepLoadingConfig,
  shippingStepSubmittedConfig,
  storeCreditToggledConfig,
  useNewShippingAddressSelectedConfig,
  refreshCartDataFinishedConfig,
} from './events';

import './custom/styles';
import { getStoredCoupon, setStoredCoupon } from './custom';

const activeCouponCode = window.EbizioEventManager.cartCouponCode;

if (
  (!getStoredCoupon() && activeCouponCode) ||
  (getStoredCoupon() !== activeCouponCode && activeCouponCode.length)
) {
  setStoredCoupon(activeCouponCode);
}

/**
 * Configuration
 */
export const ebizioEventConfig: (EbizioEventConfig | EbizioOrderEventConfig)[] =
  [
    // global
    checkoutLoadedConfig,
    couponsChangeddConfig,
    giftCertificatesChangedConfig,
    cartSummaryDesktopMountedConfig,
    cartSummaryMobileMountedConfig,
    cartSummaryModalOpenedConfig,
    // customer step
    customerSignedInConfig,
    customerSignedOutConfig,
    customerStepLoadingConfig,
    // shipping step
    shippingStepLoadingConfig,
    shippingStepSubmittedConfig,
    shippingMethodSelectedConfig,
    savedShippingAddressChangedConfig,
    useNewShippingAddressSelectedConfig,
    // billing step
    billingStepLoadingConfig,
    // payment step
    paymentStepLoadingConfig,
    paymentStepSubmittedConfig,
    paymentMethodSelectedConfig,
    paymentMethodLoadedConfig,
    storeCreditToggledConfig,
    // order confirmation
    orderConfirmationLoadedConfig,
    // refresh cart data
    refreshCartDataFinishedConfig,
  ];

/**
 * Setup Listeners
 */
ebizioEventConfig.forEach((config) => {
  if (CHECKOUT_CONTAINER) {
    CHECKOUT_CONTAINER.addEventListener(
      config.event,
      config.handler as EventListener,
    );
  }
});
