import { handleSavedCoupon } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const checkoutLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CHECKOUT_LOADED,
  handler: async ({ detail }) => {
    logger('Checkout Loaded');
    console.log(detail);

    // needed for our coupon alert mutation observer
    window.refreshCartData = detail.refreshCartData;

    // On load of checkout, update the real coupon field with the stored coupon code. (onCouponAlert mutation observer will then add it to the cloned input after the success or error message comes through)
    handleSavedCoupon(
      !!detail.coupons?.length,
      `.cart:not(.cart-clone) [name="redeemableCode"]`,
    );
  },
};
