import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const paymentMethodSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_METHOD_SELECTED,
  handler: ({ detail }) => {
    logger('Payment Method Selected');
    console.log(detail);
  },
};
