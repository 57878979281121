import { ACTIVE_COUPON_ATTR, COUPON_STORAGE_KEY } from '../../constants';
import { setInputValue, onCouponAlert } from '../../utils';
import $ from 'cash-dom';

type couponInputSelector =
  | '.checkout-step--payment .redeemable-payments [name="redeemableCode"]'
  | '.cart-modal-body [name="redeemableCode"]'
  | '.cart:not(.cart-clone) [name="redeemableCode"]';

export function handleSavedCoupon(
  cartHasCoupon: boolean,
  couponInputSelector: couponInputSelector,
) {
  if (cartHasCoupon) return;

  const couponInStorage = getStoredCoupon();

  if (!couponInStorage) return;

  setInputValue(couponInputSelector, couponInStorage);
  $(couponInputSelector).next('button').trigger('click');
}

export function getStoredCoupon() {
  return window.sessionStorage.getItem(COUPON_STORAGE_KEY);
}

export function setStoredCoupon(code: string) {
  window.sessionStorage.setItem(COUPON_STORAGE_KEY, code);
}

/**
 * Removes couponCode from session storage and also removes the body data attr data-active-coupon
 */
export function removeStoredCoupon() {
  $('body').removeAttr(`data-${ACTIVE_COUPON_ATTR}`);
  window.sessionStorage.removeItem(COUPON_STORAGE_KEY);
}

interface couponAndAlertBoxUpdateInput {
  el: Element;
  added: boolean;
  textChange: boolean | undefined;
  clonedCartCouponFormLabel:
    | '.cart-clone #redeemable-collapsable .form-label'
    | '.cart-modal-body-clone #redeemable-collapsable .form-label';
  fakeCouponField:
    | '.cart-clone [name="redeemableCode"]'
    | '.cart-modal-body-clone [name="redeemableCode"]';
}

function couponAndAlertBoxUpdate({
  el,
  added,
  textChange,
  clonedCartCouponFormLabel,
  fakeCouponField,
}: couponAndAlertBoxUpdateInput) {
  const $clonedAlert = $(el).clone();
  const $clonedCartCouponFormLabel = $(clonedCartCouponFormLabel);

  // always remove the old alertBox
  $clonedCartCouponFormLabel.siblings('.alertBox').remove();

  if (added) {
    $clonedCartCouponFormLabel.after($clonedAlert);
  } else if (textChange) {
    const $el = $(el).clone();
    if ($el.hasClass('alertBox')) $clonedCartCouponFormLabel.after($el);
  }

  const $fakeCouponField = $(fakeCouponField);

  if ($fakeCouponField.val() === '') {
    $fakeCouponField.val(getStoredCoupon() || '');
  }

  if ($clonedAlert.hasClass('alertBox--error')) {
    removeStoredCoupon();

    // TODO: pass this down instead of putting it on the window
    window.refreshCartData();
  } else if ($clonedAlert.hasClass('alertBox--success')) {
    setStoredCoupon($fakeCouponField.val().toString() || '');
  }

  // remove disable attr from our clone fields
  enableClonedCouponFields();
}

/**
 * Setup alertBox mutation tracker
 */
export function applyCouponAlertMutation() {
  // scoped so we only create mutation observer one time
  if (!$('.layout-cart').hasClass('has-coupon-mutation')) {
    // Side Cart Summary
    onCouponAlert(
      '.cart:not(.cart-clone) .legit-redeemable-collapsable .form-field',
      (added: boolean, el: Element, textChange = false) => {
        couponAndAlertBoxUpdate({
          added,
          el,
          textChange,
          clonedCartCouponFormLabel:
            '.cart-clone #redeemable-collapsable .form-label',
          fakeCouponField: '.cart-clone [name="redeemableCode"]',
        });
      },
    );
    // add mutation class to body
    $('.layout-cart').addClass('has-coupon-mutation');
  }

  // scoped so we only create mutation observer one time
  if (!$('.optimizedCheckout-cart-modal').hasClass('has-coupon-mutation')) {
    // Cart Modal Summary
    onCouponAlert(
      '.cart-modal-body:not(.cart-modal-body-clone) .legit-redeemable-collapsable .form-field',
      (added: boolean, el: Element, textChange = false) => {
        couponAndAlertBoxUpdate({
          added,
          el,
          textChange,
          clonedCartCouponFormLabel:
            '.cart-modal-body-clone #redeemable-collapsable .form-label',
          fakeCouponField: '.cart-modal-body-clone [name="redeemableCode"]',
        });
      },
    );
    // add mutation class to cart modal
    $('.optimizedCheckout-cart-modal').addClass('has-coupon-mutation');
  }
}

export function enableClonedCouponFields() {
  $(
    '.fake-redeemable-collapsable input, .fake-redeemable-collapsable button',
  ).removeAttr('disabled');
}
