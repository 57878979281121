/**
 * Remove edges, node and __typename from graphql response
 *
 * @param {Object} input - The graphql response
 * @returns {Object} Clean graphql response
 */
// @ts-ignore
export function cleanGraphQLResponse(input) {
  if (!input) return null;
  const output = {};
  // @ts-ignore
  const isObject = (obj) => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
  };

  Object.keys(input).forEach((key) => {
    if (input[key] && input[key].edges) {
      // @ts-ignore
      output[key] = input[key].edges.map((edge) =>
        cleanGraphQLResponse(edge.node),
      );
    } else if (isObject(input[key])) {
      // @ts-ignore
      output[key] = cleanGraphQLResponse(input[key]);
    } else if (key !== '__typename') {
      // @ts-ignore
      output[key] = input[key];
    }
  });

  return output;
}
