import { checkboxValueSetter, setInputValue } from './set-input-value';
import { simulateClick } from './simulate-click';

type observerCallback = (
  isLoading: boolean,
  el: Element,
  textChange?: boolean,
) => void;

const DEFAULT_CONFIG = {
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true,
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 */
const createObserver = (
  selector: string,
  options: any,
  callback: MutationCallback,
) => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector(selector);

  if (!targetNode) return;

  // Options for the observer (which mutations to observe)
  const config = { ...DEFAULT_CONFIG, ...options };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);
};

// Callback function to execute when mutations are observed
const exampleCallback = (
  mutations: MutationRecord[],
  observer: MutationObserver,
) => {
  // Use traditional 'for loops' for IE 11
  for (const mutation of mutations) {
    if (mutation.type === 'childList') {
      console.log('A child node has been added or removed.');
    } else if (mutation.type === 'attributes') {
      console.log('The ' + mutation.attributeName + ' attribute was modified.');
    }
  }
};

export const onCouponAlert = (
  selector: string,
  isLoadingCallback: observerCallback,
) => {
  createObserver(selector, {}, (mutations, observer) => {
    couponAlert(mutations, isLoadingCallback);
  });
};

/**
 * Looks for alertBox-success and alertBox--error elements being added and removed from the page
 * @param mutations
 * @param callback
 */
const couponAlert = (
  mutations: MutationRecord[],
  callback: observerCallback,
) => {
  mutations.forEach((mutation) => {
    const target = mutation.target as Element;

    if (
      !mutation.addedNodes.length &&
      !mutation.removedNodes.length &&
      mutation.type === 'characterData' &&
      target.parentElement?.classList.contains('alertBox-message')
    ) {
      const alertMessage = target.parentElement;
      const alertBox = <HTMLElement>alertMessage.parentElement;

      callback(false, alertBox, true);
    }

    mutation.addedNodes.forEach((addedNode) => {
      const addedEl = <HTMLElement>addedNode;

      if (
        addedEl?.classList?.contains('alertBox--success') ||
        addedEl?.classList?.contains('alertBox--error')
      ) {
        callback(true, addedEl);
      }
    });
    mutation.removedNodes.forEach((removedNode) => {
      const removedEl = <HTMLElement>removedNode;

      if (
        removedEl?.classList?.contains('alertBox--success') ||
        removedEl.classList.contains('alertBox--error')
      ) {
        callback(false, removedEl);
      }
    });
  });
};
