import { Consignment } from '@bigcommerce/checkout-sdk';
import $ from 'cash-dom';
import {
  DittopayCustomCouponCartPreviewResponse,
  EbizioEventDetails,
} from '../../types';
import { ACTIVE_COUPON_ATTR } from '../../constants';

export function setBodyDataActiveCoupon(
  dittopayCustomCouponCartPreview: EbizioEventDetails['dittopayCustomCouponCartPreview'],
  dittopayCustomCouponCode: EbizioEventDetails['dittopayCustomCouponCode'],
) {
  const dittoPayDiscounts =
    dittopayCustomCouponCartPreview?.discounts[0].discounted_amount || 0;

  // this is the discount amount minus the coupon amount
  const dittoPayDiscountAmount =
    dittopayCustomCouponCartPreview?.discount_amount || 0;

  const dittoPayCouponAmount = dittoPayDiscounts - dittoPayDiscountAmount;

  const couponInfo: {
    couponDiscountAmount: number;
    couponCode: string;
  } = {
    couponDiscountAmount: dittoPayCouponAmount,
    couponCode: dittopayCustomCouponCode,
  };

  $('body').data(ACTIVE_COUPON_ATTR, JSON.stringify(couponInfo));
}

export function addPreOrderBodyClass(hasPreOrderItem: boolean) {
  if (hasPreOrderItem) {
    $('body').addClass('pre-order-checkout');
  }
}

export function getActiveCouponData() {
  const couponStore = $('body').data(ACTIVE_COUPON_ATTR);
  const couponData =
    couponStore && couponStore.length ? JSON.parse(couponStore) : {};

  return couponData;
}

export function updateCartDrawerTotal(grandTotal: number) {
  const activeCouponData = getActiveCouponData();
  const newGrandTotal = activeCouponData.couponDiscountAmount
    ? grandTotal - activeCouponData.couponDiscountAmount
    : grandTotal;

  if ($(`.cartDrawer-total`).length) {
    $(`.cartDrawer-total`).text(
      newGrandTotal <= 0 ? 'TBD' : `$${newGrandTotal?.toFixed(2)}`,
    );
  }
}

export function hasValidShippingMethod(
  shipMethod: string | null,
  cartShippingOptions: Consignment[] | null,
) {
  if (
    shipMethod &&
    cartShippingOptions?.length &&
    cartShippingOptions[0].availableShippingOptions
  ) {
    return cartShippingOptions[0].availableShippingOptions.some(
      (opt) =>
        cleanAndLowercaseShipMethodString(opt.description) === shipMethod,
    );
  }

  return false;
}

export function cleanAndLowercaseShipMethodString(input: string): string {
  return input
    .replace('Domestic - ', '')
    .replace('International - ', '')
    .replace(/[^\w\s]/gi, '') // remove all non alphanumeric and whitespace
    .replace(/\s+/g, '') // remove remaining whitespace
    .toLowerCase();
}

export async function getJWT() {
  try {
    const jwtRes = await fetch(
      `/customer/current.jwt?app_client_id=4boajqwdf720bct4fwbcomuz8esrcwx`,
    );
    const token = await jwtRes.text();

    if (!token) {
      console.error('You must be logged in to submit this form.');
    }

    return token;
  } catch (err: unknown) {
    console.error('Error: ', err);
  }
}

export async function getCartPreview(cart_id: string) {
  const token = await getJWT();
  const url = `https://chargify-commerce-app.ebizio.com/app/api/renewal_preview?token=${token}`;

  const formData = new FormData();

  formData.append('cart_id', cart_id);

  try {
    const previewResponse = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    return (await previewResponse.json()) as DittopayCustomCouponCartPreviewResponse;
  } catch (error) {
    return null;
  }
}

export function canSetCacheItem({
  key,
  data,
  cachedItem,
  shouldRevalidate,
}: {
  key: string;
  data: string;
  cachedItem: string | null;
  shouldRevalidate?: boolean;
}) {
  if (!sessionStorage) return true; // if we have no storage always revalidate

  if ((cachedItem && cachedItem !== data) || !cachedItem) {
    sessionStorage.setItem(key, data);
    if (shouldRevalidate) {
      return true;
    }
  }

  return false;
}

export function getCacheItem(key: string) {
  if (!sessionStorage) return null;

  return sessionStorage.getItem(key);
}

export function removeCacheItem(key: string) {
  sessionStorage.removeItem(key);
}

const PRE_ORDER_OPTION_CHANGED = 'pre-order-option-changed';

export function setProductOptionChangedInStorage() {
  sessionStorage.setItem(PRE_ORDER_OPTION_CHANGED, 'true');
}

export function hasProductOptionChangedStorage() {
  return sessionStorage.getItem(PRE_ORDER_OPTION_CHANGED) === 'true';
}

export function removeProductOptionChangedFromStorage() {
  sessionStorage.removeItem(PRE_ORDER_OPTION_CHANGED);
}
