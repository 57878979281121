import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const giftCertificatesChangedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.GIFT_CERTIFICATES_CHANGED,
  handler: ({ detail }) => {
    logger('Gift Certificates Changed');
    console.log(detail);
  },
};
