import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

// NOTE: this will not fire all ALL payment methods loaded
// -- it's difficult to get right so if you need this hook and it's not working for your specific payment method please message Aaron
export const paymentMethodLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_METHOD_LOADED,
  handler: ({ detail }) => {
    logger('Payment Method Loaded');
    console.log(detail);
  },
};
