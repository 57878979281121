import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const paymentStepSubmittedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_SUBMITTED,
  handler: ({ detail }) => {
    logger('Payment Step Submitted');
    setActiveStepOnBody(detail.activeStepType);
    console.log(detail);
  },
};
