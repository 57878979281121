export * from './helpers';

import { Cart, Consignment, PhysicalItem } from '@bigcommerce/checkout-sdk';
import { cleanGraphQLResponse, lang, setInputValue } from '../../utils';
import $, { Cash } from 'cash-dom';
import { EbizioEventDetails } from '../../types';
import {
  applyCouponAlertMutation,
  getStoredCoupon,
  removeStoredCoupon,
} from '../coupons';
import {
  canSetCacheItem,
  cleanAndLowercaseShipMethodString,
  getActiveCouponData,
  getCacheItem,
  getCartPreview,
} from './helpers';

interface BCProduct {
  customFields: Array<{
    name: string;
    value: string;
  }>;
}

interface GetPreOrderInformationReturnObject {
  preOrderItem: PhysicalItem | null;
  hasPreOrderItem: boolean;
  shippingMethod: string;
  shippingTotal: number;
  subTotal: number;
  grandTotal: number;
  retailPrice: number;
  couponDiscountTotal: number;
}

// TODO: should we cache the requests in this function so we're not spamming our endpoint
export async function getPreOrderInformation(
  cart: Cart,
  consignments: Consignment[] | null,
) {
  // Get cached data
  const cachedFirstCartItem = getCacheItem(`firstCartItem-${cart.id}`);
  const cachedPreOrderProduct = getCacheItem(`preOrderProduct-${cart.id}`);
  const cachedCartPreview = getCacheItem(`cartPreview-${cart.id}`);

  let revalidateCache = false;
  let returnObj: GetPreOrderInformationReturnObject = {
    preOrderItem: null,
    hasPreOrderItem: false,
    shippingMethod: '',
    shippingTotal: 0,
    retailPrice: 0,
    subTotal: 0,
    grandTotal: 0,
    couponDiscountTotal: 0,
  };

  const { firstCartItem, firstCartItemSelectedShippingMethod } =
    getFirstCartItemInfo(cart.lineItems.physicalItems);
  if (!firstCartItem) return returnObj;

  revalidateCache = canSetCacheItem({
    key: `firstCartItem-${cart.id}`,
    data: JSON.stringify(firstCartItem),
    cachedItem: cachedFirstCartItem,
    shouldRevalidate: true,
  });

  // Fetch product via graphql to get custom field data
  const product =
    revalidateCache || !cachedPreOrderProduct
      ? ((await getProduct(firstCartItem.productId)) as BCProduct)
      : (JSON.parse(cachedPreOrderProduct) as BCProduct);
  if (!product) return returnObj;

  canSetCacheItem({
    key: `preOrderProduct-${cart.id}`,
    data: JSON.stringify(product),
    cachedItem: cachedPreOrderProduct,
  });

  // Check if the item is a pre order item
  const isPreOrderItem = checkForPreOrderCustomField(product.customFields);
  if (!isPreOrderItem || !firstCartItemSelectedShippingMethod) return returnObj;

  returnObj.preOrderItem = firstCartItem;
  returnObj.hasPreOrderItem = true;
  returnObj.shippingMethod = firstCartItemSelectedShippingMethod;
  returnObj.retailPrice = firstCartItem.retailPrice * firstCartItem.quantity;
  returnObj.shippingTotal =
    getShippingCostOfItem(firstCartItemSelectedShippingMethod, consignments) ||
    0;

  const cartPreview =
    revalidateCache || !cachedCartPreview
      ? await getCartPreview(cart.id)
      : JSON.parse(cachedCartPreview);

  if (!cartPreview || !cartPreview.data || !cartPreview.data.preview)
    return returnObj;

  canSetCacheItem({
    key: `cartPreview-${cart.id}`,
    data: JSON.stringify(cartPreview),
    cachedItem: cachedCartPreview,
  });

  const discountedAmount =
    cartPreview.data.preview.base_amount -
    (cartPreview.data.preview.discount_amount || 0);

  returnObj.subTotal = discountedAmount;
  returnObj.grandTotal =
    parseFloat(returnObj.subTotal.toFixed(2)) +
      parseFloat(returnObj.shippingTotal.toFixed(2)) || 0;

  return returnObj;
}

interface replaceSummaryWithCloneInput {
  shippingTotal: number;
  retailPrice: number;
  subTotal: number;
  grandTotal: number;
  hasValidShippingMethod: boolean;
  dittopayCustomCouponCartPreview?: EbizioEventDetails['dittopayCustomCouponCartPreview'];
}

export async function replaceSummaryWithClone({
  shippingTotal,
  retailPrice,
  subTotal,
  grandTotal,
  hasValidShippingMethod,
}: replaceSummaryWithCloneInput) {
  const summaryClassName = $('.optimizedCheckout-cart-modal').length
    ? 'cart-modal-body'
    : 'cart';

  const cloneClass = getCloneClassName(summaryClassName);
  const $cartEl = $(`.${summaryClassName}:not(.${cloneClass})`);
  const $clone = $cartEl.clone();

  $cartEl
    .find('#redeemable-collapsable')
    .addClass('legit-redeemable-collapsable');
  $clone.addClass(cloneClass);
  $clone
    .find('#redeemable-collapsable')
    .addClass('fake-redeemable-collapsable')
    .removeClass('legit-redeemable-collapsable'); // for some reason this class is being added to our cloned element (looked for fixes but came up empty. this was an easy solution)

  const activeCouponData = getActiveCouponData();

  if (activeCouponData.couponCode) {
    addCouponLineItem($clone, activeCouponData);
  }

  updateProductPrice($clone, retailPrice, subTotal);
  updateSubTotal($clone, subTotal);
  updateShippingTotal($clone, shippingTotal, hasValidShippingMethod);

  const newGrandTotal = activeCouponData.couponDiscountAmount
    ? grandTotal - activeCouponData.couponDiscountAmount
    : grandTotal;

  updateGrandTotal($clone, newGrandTotal);

  // Switch tax field value to TBD
  $clone
    .find('[data-test="cart-taxes"] .cart-priceItem-value span')
    .text(`TBD`);

  // Replace promo field text
  $clone.find('.redeemable-label').text('Coupon');

  $clone.append(
    `<section class="cart-section optimizedCheckout-orderSummary-cartSection">${lang(
      'pre_order_messaging',
    )}</section>`,
  );

  const $clonedCouponField = $clone.find(
    '.fake-redeemable-collapsable [name="redeemableCode"]',
  );
  const storedCoupon = getStoredCoupon();

  if (storedCoupon && $clonedCouponField.val() === '') {
    $clonedCouponField.val(storedCoupon);
  }

  bindEvents($clone, $cartEl);

  $(`.${cloneClass}`).remove(); // Always remove the clone that is on the page so we can add the newly generated one.
  $cartEl.after($clone); // replace the contents of our clone

  applyCouponAlertMutation();
}

// private functions

function getFirstCartItemInfo(cartItems: PhysicalItem[]) {
  const firstCartItem = cartItems.length ? cartItems[0] : null;
  const firstCartItemOptions = firstCartItem?.options?.find(
    (opt) => opt.name === 'Ship Method',
  );
  const shipMethod = firstCartItemOptions?.value || '';

  const firstCartItemSelectedShippingMethod =
    cleanAndLowercaseShipMethodString(shipMethod);

  return {
    firstCartItem,
    firstCartItemOptions,
    firstCartItemSelectedShippingMethod,
  };
}

async function getProduct(id: PhysicalItem['id']) {
  try {
    const response = await fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.EbizioEventManager.gqlToken}`,
      },
      body: JSON.stringify({
        query: `
          query productById($productId: Int!){
            site {
              product(entityId: $productId) {
                customFields {
                  edges {
                    node{
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          productId: id,
        },
      }),
    });

    const data = await response.json();
    const product = cleanGraphQLResponse(data?.data?.site?.product);

    return product;
  } catch (error) {}
}

function checkForPreOrderCustomField(customFields: BCProduct['customFields']) {
  const isPreOrderItem = customFields.filter(
    (item) => item.name === '__product-type' && item.value === 'pre-order',
  );

  return !!isPreOrderItem.length;
}

function getShippingCostOfItem(
  shipMethod: string | null,
  cartShippingOptions: Consignment[] | null,
) {
  if (!shipMethod || !cartShippingOptions || !cartShippingOptions.length)
    return;

  const selectedShippingMethod =
    cartShippingOptions[0].availableShippingOptions?.find(
      (opt) =>
        cleanAndLowercaseShipMethodString(opt.description) === shipMethod,
    );

  const shippingCost = selectedShippingMethod?.cost;

  return shippingCost ? shippingCost : 0;
}

function getCloneClassName(summaryClassName: string) {
  return `${summaryClassName}-clone`;
}

function addCouponLineItem($clone: Cash, couponData: any) {
  $clone.find('[data-test="cart-subtotal"]').after(`
    <div data-test="cart-coupon" class="js-fake-coupon-line">
      <div aria-live="polite" class="cart-priceItem optimizedCheckout-contentPrimary">
        <span class="cart-priceItem-label">
          <span data-test="cart-price-label" class="js-fake-coupon-label">Coupon Applied to Order</span>
          <span class="cart-priceItem-link">
            <a data-test="cart-price-callback" href="#" class="js-fake-coupon-remove">remove</a>
          </span>
        </span>
        <span class="cart-priceItem-value">
          <span data-test="cart-price-value" class="js-fake-coupon-value">-$${couponData.couponDiscountAmount.toFixed(
            2,
          )}</span>
        </span>
        <span class="cart-priceItem-postFix optimizedCheckout-contentSecondary js-fake-coupon-code"
          data-test="cart-price-code">${couponData.couponCode}</span>
      </div>
    </div>
  `);
  $clone.find('.js-fake-coupon-remove').on('click', (e) => {
    e.preventDefault();
    removeStoredCoupon();
    window.location.reload();
  });
}

function updateProductPrice(
  $clone: Cash,
  retailPrice: number,
  subTotal: number,
) {
  if (retailPrice !== subTotal && subTotal !== 0) {
    $clone.find('.product-column.product-actions')
      .prepend(`<div class="product-price optimizedCheckout-contentPrimary product-price--beforeDiscount"
    data-test="cart-item-product-price">$${retailPrice.toFixed(2)}</div>`);
  }

  $clone
    .find('.product-price:not(.product-price--beforeDiscount)')
    .text(subTotal === 0 ? 'TBD' : `$${subTotal?.toFixed(2)}`);
}

function updateSubTotal($clone: Cash, subTotal: number) {
  $clone
    .find('.cart-priceItem--subtotal .cart-priceItem-value span')
    .text(subTotal === 0 ? 'TBD' : `$${subTotal?.toFixed(2)}`);
}

function updateShippingTotal(
  $clone: Cash,
  shippingTotal: number,
  hasValidShippingMethod: boolean,
) {
  $clone
    .find('[data-test="cart-shipping"] .cart-priceItem-label span')
    .text('Estimated Shipping');
  $clone
    .find('[data-test="cart-shipping"] .cart-priceItem-value span')
    .text(
      shippingTotal === 0 && !hasValidShippingMethod
        ? 'TBD'
        : `$${shippingTotal?.toFixed(2)}`,
    );
}

function updateGrandTotal($clone: Cash, grandTotal: number) {
  $clone
    .find('.cart-priceItem--total')
    .find('.cart-priceItem-value span')
    .text(grandTotal === 0 ? 'TBD' : `$${grandTotal?.toFixed(2)}`);
}

function bindEvents($clonedSummary: Cash, $cartEl: Cash) {
  // events

  // On Enter key, trigger click on apply button
  $clonedSummary.find('[name="redeemableCode"]').on('keydown', (e) => {
    if (e.key === 'Enter') {
      $clonedSummary.find('#applyRedeemableButton').trigger('click');
    }
  });

  // on click of fake apply button, update and change real coupon field and click real button
  $clonedSummary.find('#applyRedeemableButton').on('click', (e) => {
    const $el = $(e.currentTarget);

    const couponCode = $(e.currentTarget)
      .siblings('[name="redeemableCode"]')
      .val();

    if (couponCode.length === 0) return;

    $el.attr('disabled', 'true');
    $el.siblings('input').attr('disabled', 'true');

    setInputValue(
      '.legit-redeemable-collapsable [name="redeemableCode"]',
      couponCode,
    );

    $cartEl.find('#applyRedeemableButton').trigger('click');
  });
}
