import { ACTIVE_COUPON_ATTR } from '../constants';
import {
  addPreOrderBodyClass,
  getPreOrderInformation,
  hasProductOptionChangedStorage,
  hasValidShippingMethod,
  removeProductOptionChangedFromStorage,
  replaceSummaryWithClone,
  updateCartDrawerTotal,
} from '../custom';
import { EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import $ from 'cash-dom';

export const refreshCartDataFinishedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.REFRESH_CART_DATA_FINISHED,
  handler: async ({ detail }) => {
    logger('Refresh Cart Data');
    console.log(detail);

    const activeCoupon = $('body').data(ACTIVE_COUPON_ATTR);
    const $couponLineItem = $('.js-fake-coupon-line');

    if (detail.cart) {
      // (hasProductOptionChangedStorage + removeProductOptionChangedFromStorage) - This is here because of a ship method option change issues (when the user is prompted to change the shipping method on the pre-order product because the shipping method isn't available for this order (triggers on payment step). After the user selects a new option the page reloads and the events that trigger the summary window updates fire in a weird order and the summary gets overwritten with stale data. aka a weird timing issue that doesn't occur when the page is manually reloaded on the payment step)
      if (
        (!activeCoupon && $couponLineItem.length) ||
        hasProductOptionChangedStorage()
      ) {
        const {
          hasPreOrderItem,
          shippingMethod,
          retailPrice,
          grandTotal,
          shippingTotal,
          subTotal,
        } = await getPreOrderInformation(detail.cart, detail.consignments);

        if (!hasPreOrderItem) return;

        addPreOrderBodyClass(hasPreOrderItem);

        updateCartDrawerTotal(grandTotal);

        replaceSummaryWithClone({
          retailPrice,
          shippingTotal,
          subTotal,
          grandTotal,
          hasValidShippingMethod: hasValidShippingMethod(
            shippingMethod,
            detail.consignments,
          ),
        });

        removeProductOptionChangedFromStorage();
      }
    }
  },
};
