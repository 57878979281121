import {
  getPreOrderInformation,
  hasValidShippingMethod,
  replaceSummaryWithClone,
  setBodyDataActiveCoupon,
  updateCartDrawerTotal,
} from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const couponsChangeddConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.COUPONS_CHANGED,
  handler: async ({ detail }) => {
    logger('Coupons Changed');
    console.log(detail);

    if (detail.cart) {
      const {
        hasPreOrderItem,
        shippingMethod,
        retailPrice,
        grandTotal,
        shippingTotal,
        subTotal,
      } = await getPreOrderInformation(detail.cart, detail.consignments);

      if (!hasPreOrderItem) return;

      setBodyDataActiveCoupon(
        detail.dittopayCustomCouponCartPreview,
        detail.dittopayCustomCouponCode,
      );

      replaceSummaryWithClone({
        retailPrice,
        shippingTotal,
        subTotal,
        grandTotal,
        hasValidShippingMethod: hasValidShippingMethod(
          shippingMethod,
          detail.consignments,
        ),
      });

      updateCartDrawerTotal(grandTotal);
    }
  },
};
