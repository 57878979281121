import {
  addPreOrderBodyClass,
  getPreOrderInformation,
  hasValidShippingMethod,
  replaceSummaryWithClone,
  updateCartDrawerTotal,
} from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerSignedInConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_IN,
  handler: async ({ detail }) => {
    logger('Customer Signed In');
    console.log(detail);

    if (detail.cart) {
      const {
        hasPreOrderItem,
        shippingMethod,
        retailPrice,
        grandTotal,
        shippingTotal,
        subTotal,
      } = await getPreOrderInformation(detail.cart, detail.consignments);

      if (!hasPreOrderItem) return;

      addPreOrderBodyClass(hasPreOrderItem);

      replaceSummaryWithClone({
        retailPrice,
        shippingTotal,
        subTotal,
        grandTotal,
        hasValidShippingMethod: hasValidShippingMethod(
          shippingMethod,
          detail.consignments,
        ),
      });

      updateCartDrawerTotal(grandTotal);
    }
  },
};
