import { LanguageConfig } from '@bigcommerce/checkout-sdk';
import { createLanguageService } from '../../vendor/@bigcommerce/checkout-sdk-js';
import fallbackLang from './fallbackLang';

declare global {
  interface Window {
    language: LanguageConfig;
  }
}

const language = window.language;

// We don't need fallbackLocales because the language service defaults to en if no locales are found for our custom key. It also flattens our fallbackLang keys for us so we can pass it normal JSON.
if (language && Object.keys(fallbackLang).length) {
  language.fallbackTranslations = {
    ...language.fallbackTranslations,
    ...fallbackLang,
  };
}

const service = createLanguageService(language);

export const lang = (key: string, variables: Record<string, string> = {}) => {
  const value = Object.keys(variables).length
    ? service.translate(`its_event_manager.${key}`, { ...variables })
    : service.translate(`its_event_manager.${key}`);

  return value;
};
