import {
  cleanAndLowercaseShipMethodString,
  getPreOrderInformation,
  handleSavedCoupon,
  hasProductOptionChangedStorage,
  hasValidShippingMethod,
  setProductOptionChangedInStorage,
} from '../custom';
import { EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';
import $ from 'cash-dom';
import Swal from 'sweetalert2';
//@ts-ignore
import utils from '@bigcommerce/stencil-utils';
import { Consignment } from '@bigcommerce/checkout-sdk';

export const paymentStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_LOADING,
  handler: async ({ detail }) => {
    logger('Payment Step Loading');
    setActiveStepOnBody(detail.activeStepType);
    console.log(detail);

    handleSavedCoupon(
      !!detail.coupons?.length,
      '.checkout-step--payment .redeemable-payments [name="redeemableCode"]',
    );

    if (detail.cart) {
      const consignments = detail.consignments;

      const { preOrderItem, hasPreOrderItem, shippingMethod } =
        await getPreOrderInformation(detail.cart, consignments);

      if (!preOrderItem || !hasPreOrderItem) return;

      if (!hasValidShippingMethod(shippingMethod, consignments)) {
        $('#checkout-payment-continue').remove();

        utils.api.productAttributes.configureInCart(
          preOrderItem.id,
          {},
          (_: any, response: any) => {
            const { form, errors } = getShipMethodForm(response, consignments);

            Swal.fire({
              title: 'Invalid Shipping Option',
              icon: 'error',
              width: '500px',
              html: `
                <p>Unfortunately, the item(s) in your order is not available with the selected shipping option.  This may be for a variety of reasons like domestic on an international order, or if you have ordered multiple items and the package is now too large.</p>
                <p>Please select an alternate shipping method. We apologize for the inconvenience!</p>
                ${form}
                `,
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                $('#shipMethodUpdateForm').on('submit', (e) => {
                  e.preventDefault();
                  const formData = $(e.currentTarget).serialize();
                  const URL = `/cart.php?action=EditProductFieldsInCart&${formData}`;

                  const $submitButton = $(e.currentTarget).find(
                    'button[type="submit"]',
                  );

                  $submitButton
                    .attr('disabled', 'disabled')
                    .addClass('is-loading');

                  fetch(URL, { method: 'POST' })
                    .then(async (res) => {
                      if (res.ok) {
                        setProductOptionChangedInStorage();
                        window.location.href = '/checkout';
                      } else {
                        $submitButton
                          .removeAttr('disabled')
                          .removeClass('is-loading');
                        $('#shipMethodErrors').html(errors);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              },
            });
          },
        );
      }

      if (hasProductOptionChangedStorage()) {
        await detail.refreshCartData();
      }
    }
  },
};

function getShipMethodForm(response: any, consignments: Consignment[] | null) {
  const shipMethodOption = response.data?.options.find(
    (opt: any) => opt.display_name === 'Ship Method',
  );

  const approvedShippingMethods = shipMethodOption.values.filter(
    (val: { label: string }) => {
      if (consignments) {
        return consignments[0].availableShippingOptions?.some(
          (opt) =>
            cleanAndLowercaseShipMethodString(opt.description) ===
            cleanAndLowercaseShipMethodString(val.label),
        );
      }

      return false;
    },
  );

  const form = `
    <form id="shipMethodUpdateForm" method="post" enctype="multipart/form-data>
      <input type="hidden" name="action" value="EditProductFieldsInCart">
      <input type="hidden" name="item_id" value="${response.data
        ?.quote_item_id}">
      <div class="form-field" style="margin-bottom: 2rem;">
        <label class="form-label optimizedCheckout-form-label" for="attribute_select_${
          shipMethodOption.id
        }" style="text-align: left;">
            Ship Method:
        </label>
        <div class="dropdown-chevron"><div class="icon"><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></div></div>
        <select class="form-select optimizedCheckout-form-select" name="attribute[${
          shipMethodOption.id
        }]" id="attribute_select_${shipMethodOption.id}" required="">
          <option value="">Select Shipping Method</option>
          ${approvedShippingMethods.map(
            (method: { label: string; id: number }) =>
              `<option value="${method.id}">${method.label}</option>`,
          )}
        </select>
        <div id="shipMethodErrors" class="form-field--error" style="text-align: left;"></div>
      </div>
      <button class="button button--primary optimizedCheckout-buttonPrimary" type="submit" style="margin-bottom: 0;">Modify My Shipping Method</button>
    </form>
  `;

  // could be fancier but works for now
  const errors = `
    <ul class="form-field-errors">
      <li class="form-field-error">
        <label aria-live="polite" class="form-inlineMessage" for="attribute[${shipMethodOption.id}]" role="alert">An error has occurred. Please refresh the page and try again.</label>
      </li>
    </ul>
  `;

  return { form, errors };
}
