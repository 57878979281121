import {
  replaceSummaryWithClone,
  getPreOrderInformation,
  hasValidShippingMethod,
  handleSavedCoupon,
} from '../custom';
import { EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryModalOpenedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MODAL_OPENED,
  handler: async ({ detail }) => {
    logger('Cart Summary Modal Opened');
    console.log(detail);

    handleSavedCoupon(
      !!detail.coupons?.length,
      '.cart-modal-body [name="redeemableCode"]',
    );

    if (detail.cart) {
      const {
        hasPreOrderItem,
        shippingMethod,
        retailPrice,
        grandTotal,
        shippingTotal,
        subTotal,
      } = await getPreOrderInformation(detail.cart, detail.consignments);

      if (!hasPreOrderItem) return;

      replaceSummaryWithClone({
        retailPrice,
        shippingTotal,
        subTotal,
        grandTotal,
        hasValidShippingMethod: hasValidShippingMethod(
          shippingMethod,
          detail.consignments,
        ),
      });
    }
  },
};
