import {
  addPreOrderBodyClass,
  getPreOrderInformation,
  updateCartDrawerTotal,
} from '../custom';
import { EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryMobileMountedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MOBILE_MOUNTED,
  handler: async ({ detail }) => {
    logger('Cart Summary Mobile Mounted');
    console.log(detail);

    if (detail.cart) {
      const { hasPreOrderItem, grandTotal } = await getPreOrderInformation(
        detail.cart,
        detail.consignments,
      );

      if (!hasPreOrderItem) return;

      addPreOrderBodyClass(hasPreOrderItem);

      updateCartDrawerTotal(grandTotal);
    }
  },
};
